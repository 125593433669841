import { defineComponent, onMounted, reactive } from 'vue'
import { SET_USER, SET_ORDER_INFO } from '../../../utils/constant'
import { useRouter } from 'vue-router'
import './index.less'


export default defineComponent({
    setup () {
        const router = useRouter()
        const state: any = reactive({
            user: {}
        })

        onMounted(() => {
            let res: any = localStorage.getItem(SET_USER)
            let order: any = localStorage.getItem(SET_ORDER_INFO)
            console.log(order, '获取得数据。。。')
            if (res) {
                res = JSON.parse(res)
                state.user = res.user
            }
            if (order) {
                order = JSON.parse(order)
                state.user = { ...state.user,  createTime: order.createTime, sn: order.sn}
            }
        })

        const onBackAmount = () => {
            router.push({path: '/amount'})
        }

        return () => (
            <div class="personal-page">
                <div class="header-box flex-box justify-content-between plr35">
                    <div>账户充值</div>
                    <img class="logo" src="https://img.fanwoon.com/new_logo.png" />
                </div>
                <div class="result-final-header-box">
                    <div>
                        <img src='https://img.fanwoon.com/weibiaoti38%402x.png' />
                    </div>
                    <div class="success-font">充值成功</div>
                </div>
                <div class="success-result-box">
                    <div class="item-box"><span class="label-box">类别：</span>余额充值</div>
                    <div class="item-box"><span class="label-box">创建时间：</span>{state.user.createTime}</div>
                    <div class="item-box"><span class="label-box">流水号：</span>{state.user.sn}</div>
                    <div class="item-box"><span class="label-box">操作用户：</span>{state.user.name}（{state.user.phone}）</div>
                    <div class="item-box"><span class="label-box">状态：</span>充值成功</div>
                </div>
                <div class="success-footer">
                    <button class="back-btn" onClick={onBackAmount}>返回</button>
                </div>
            </div>
        )
    }
})